/* src/App.css */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@400;500;600;700&display=swap');

body, html {
  font-family: 'IBM Plex Sans Thai', sans-serif;
}

h1, h2, h3, h4, h5, h6, p, button, span, .menu, .content {
  font-family: 'IBM Plex Sans Thai', sans-serif;
}

/* src/App.css */
.hero-image {
  height: 100vh; /* ทำให้วิดีโอเต็มหน้าจอ */
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* ป้องกันวิดีโอหลุดออกนอกขอบเขตที่กำหนด */
}

.hero-video {
  width: 100%; /* กำหนดความกว้าง */
  height: 100%; /* กำหนดความสูง */
  object-fit: cover; /* ให้วิดีโอครอบคลุมพื้นที่ทั้งหมด โดยรักษาอัตราส่วน */
  position: absolute;
  top: 0;
  left: 0;
}

.black-bar {
  height: 10vh; /* ความสูงของแถบสีดำ */
  background-color: black;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; /* ปรับเพิ่มระยะห่างด้านใน */
  color: rgb(134, 134, 134); /* สีของข้อความ */
  z-index: 1;
}
.black-bar img{
  opacity: 0.5;
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu {
  position: absolute;
  top: 5%; /* ปรับตามความต้องการ */
  left: 50%;
  transform: translate(-50%, -10%);
  display: flex;
  gap: 20px; /* ระยะห่างระหว่างเมนู */
  z-index: 1;
}
.menu button {
  display: flex;
  align-items: center;
  gap: 10px; /* ระยะห่างระหว่างข้อความและรูปภาพ */
  background-color: transparent; /* พื้นหลังโปร่งใส */
  border: none; /* ไม่มีเส้นขอบ */
  color: white; /* สีของข้อความ */
  cursor: pointer; /* เปลี่ยนเคอร์เซอร์เป็นรูปมือเมื่อชี้ */
  font-family: 'IBM Plex Sans Thai', sans-serif; /* ใช้ฟอนต์ที่กำหนด */
  font-weight: 500;
  font-size: 18px; /* ขนาดตัวอักษร */
}

.menu img {
  width: 30px; /* ปรับขนาดรูปภาพ */
  height: 25px; /* ปรับขนาดรูปภาพ */
}




.hamburger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  cursor: pointer;
  position: absolute; /* ทำให้ปุ่มอยู่บนสุด */
  top: 20px; /* ปรับตามความต้องการ */
  left: 20px; /* ปรับตามความต้องการ */
  z-index: 1; /* ให้แน่ใจว่าปุ่มอยู่บนสุด */
}

.hamburger-menu .bar {
  width: 100%;
  height: 3px;
  background-color: white;
}

.dropdown-menu {
  position: absolute;
  top: 8%;
  left: 30%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.8);

  padding: 10px;
  z-index: 1;
}
.dropdown-menu img {
  width: 100px; /* ตั้งค่าความกว้างของภาพเมนู */
}
.dropdown-menu button{
  font-family: 'IBM Plex Sans Thai', sans-serif; /* ใช้ฟอนต์ที่กำหนด */
  font-weight: 1000;
  padding-left: 10px;
  border-style: none;
  background-color: #00000000;
  color: azure;
}

.logo {
  position: absolute;
  left: 40%; /* ตั้งค่าตำแหน่งซ้ายให้อยู่กลาง */
  top: 50%; /* ตั้งค่าตำแหน่งบนให้อยู่กลาง */
  transform: translate(-50%, -50%); /* ใช้ transform เพื่อให้โลโก้อยู่ตรงกลางจริงๆ */
}

.logo img {
  width: 60%; /* ตั้งค่าความกว้างของภาพโลโก้เป็น 20% ของพื้นที่ใช้งานได้ */
  height: auto; /* ความสูงจะปรับตามอัตโนมัติให้เหมาะสมกับความกว้าง */
}

.footer-content {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
}

.social-links img {
  width: 34px; /* ขนาดของไอคอนโซเชียล */
}

.toggle-music {
  position: absolute;
  bottom: 150px; /* ปรับตำแหน่งตามต้องการ */
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 16px;
  font-size: 8px;
  color: white;
  background: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  z-index: 2;
}

.toggle-music img {
  width: 40px;
}

.update-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 50%; /* ครึ่งหนึ่งของหน้าเว็บ */
  height: 100%;
  background-color: rgb(0, 0, 0);
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.5);
  overflow-y: auto; /* Allows scrolling */
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 100; /* ให้แน่ใจว่าป๊อปอัพจะแสดงอยู่เหนือเนื้อหาอื่นๆ */
}

.update-content h4 {
  margin-top: 20px;
  margin-bottom: 20px;
  color: rgb(73, 145, 115);
}

.update-content h5 {
  margin-top: 0px;
  color: rgb(37, 109, 138);
}

.update-content h6 {
  margin-top: 50px;
  color: rgb(37, 144, 177);
}

.update-content p {
  color: rgb(82, 91, 94);
  padding-left: 20px;
}

.update-content ul {
  color: rgb(82, 91, 94);
}

.update-content hr {
  border: none; /* ลบเส้นขอบเริ่มต้น */
  height: 1px; /* ตั้งความสูงของเส้น */
  background-color: rgb(136, 136, 136); /* ตั้งสีของเส้น */
  margin: 20px 0; /* เว้นระยะด้านบนและล่าง */
}

.close-button {
  background-color: black; /* สีพื้นหลัง */
  color: white; /* สีตัวอักษร */
  border: none; /* ลบเส้นขอบ */
  padding: 10px 20px; /* ระยะห่างภายใน */
  font-family: 'Roboto', sans-serif; /* ตั้งค่าฟอนต์ */
  font-size: 16px; /* ขนาดตัวอักษร */
  font-weight: 700; /* ความหนาของตัวอักษร */
  cursor: pointer; /* ให้เมาส์เปลี่ยนเป็นมือเมื่อชี้ */
  transition: background 0.3s ease; /* เพิ่มเอฟเฟ็กต์เมื่อ hover */
}

.close-button:hover {
  background-color: #333; /* สีพื้นหลังเมื่อ hover */
}

.contact-form-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-color: black; /* Background color set to black */
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: white; /* Make text color white for visibility on black background */
  overflow-y: auto;
  z-index: 100;
}

.waiting-message{
  display: flex;
  flex-direction: column;
  justify-content: center; /* Adjust spacing or use if needed */
  margin-top: 50%; /* Helps in further centering if outer flex doesn't suffice */
  color: gray;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Adjust spacing or use if needed */
  margin-top: 20%; /* Helps in further centering if outer flex doesn't suffice */
}

.contact-form label {
  margin-top: 20px;
}

.contact-form input,
.contact-form textarea {
  margin-top: 5px;
  background-color: #333; /* Darker shade for input fields */
  color: white; /* Text color white for visibility */
  border: none; /* Remove border */
}

.contact-form textarea {
  height: 150px; /* Make the message box larger */
}

.contact-form button {
  background-color: black; /* Button background black */
  color: white; /* Button text white */
  padding: 10px 20px;
  border: 1px solid white; /* Optional: add a white border to the button */
  cursor: pointer;
  margin-top: 20px;
}

.contact-form button:hover {
  opacity: 0.8; /* Slight effect on hover */
}
.contact-form-panel hr{
  margin-top: 10px;
}
.infoMessage-panel{
  margin-top: 80px;
  color: grey;
}
.cookie-notification {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: black;
  color: white;
  text-align: center;
  padding: 10px;
  z-index: 1001;
}

.cookie-notification p {
  margin: 0;
}

.cookie-notification button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
  margin-left: 5px;
}

.cookie-notification button:hover {
  background-color: #555;
}




.hero-image-bottom {
  width: 100%; /* Ensure it stretches across */
  height: auto; /* Maintain aspect ratio */
  margin-top: auto; /* Pushes to the bottom of the flex container */
}

.story-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #000000 0%, #11111a 100%); /* Gradient background */
  color: white;
  z-index: 1000; /* Ensures it stays on top */
  display: flex;
  flex-direction: column;

  align-items: center;
  text-align: center;
  margin: auto;
  overflow-y: auto; /* Allows scrolling */
  z-index: 10;
}

.story-content {
  text-align: center;
  margin: auto;
}


.image-container {
  display: flex;
  justify-content: center; /* Align images in the center if there is extra space */
  align-items: center; /* Align images vertically */
}

.story-image {
  width: 50%; /* Each image takes up half of the container */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure images cover the area properly without distorting */
  margin-right: -4px; /* Overlap images slightly to remove any visible gap; adjust per your images */
}

.story-image:last-child {
  margin-right: 0; /* No margin on the right for the last image */
}

.story-buttons button {
  padding: 10px 20px;
  margin: 5px;
  background-color: #333333;
  color: white;
  border: none;
  cursor: pointer;
}

.story-buttons button:hover {
  background-color: #222222; /* Slightly lighter black on hover */
}

.characters-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #000000 0%, #11111a 100%); /* Gradient background */
  color: white;
  z-index: 1000; /* Ensures it stays on top */
  display: flex;
  flex-direction: column;

  align-items: center;
  text-align: center;
  margin: auto;
  overflow-y: auto; /* Allows scrolling */
  z-index: 10;
}

.characters-content {
  text-align: center;
  margin: auto;
}


.characters-image {
  width: 100%; /* Each image takes up half of the container */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure images cover the area properly without distorting */
  margin-right: -4px; /* Overlap images slightly to remove any visible gap; adjust per your images */
}

.characters-image:last-child {
  margin-right: 0; /* No margin on the right for the last image */
}

.characters-buttons button {
  font-size: 18px;
  padding: 10px 20px;
  margin: 5px;
  background-color: #3333334b;
  color: white;
  border: none;
  cursor: pointer;
}

.characters-buttons button:hover {
  background-color: #222222; /* Slightly lighter black on hover */
}


.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.loading-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1001;
}

.gallery-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #000000 0%, #11111a 100%); /* Gradient background */
  color: white;
  z-index: 1000; /* Ensures it stays on top */
  display: flex;
  overflow-y: auto; /* Allows scrolling */
}

.gallery-topInfo{
  padding: 10px;
  color: rgba(255, 255, 255, 0.24);
  border: none;
  cursor: pointer;

}
.gallery-topInfo h6{
  padding-left: 45px;
}
.iconGallery{
  width: 150px;
  opacity: 0.5;
}

.gallery-content {
  padding: 20px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.gallery-item {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  transition: transform 0.3s ease;
  filter: brightness(0.6);
}

.gallery-item:hover img {
  transform: scale(1.1);
  filter: brightness(1);
}

.gallery-item:nth-child(4n + 3),
.gallery-item:nth-child(4n + 4) {
  grid-column: span 2;
}

/* Selected Image Styles */
.selected-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.selected-image img {
  max-width: 100%;
  max-height: 100%;
}

.support-dev-button {
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 13%;
  right: -15px;
  background-color: #000000ce;
  font-family: 'IBM Plex Sans Thai', sans-serif;
  font-size: 14px;
  border-style: none;
  color: azure;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 10px 20px;
  z-index: 1;
}

.heart-icon {
  position: fixed;
  width: 24px;
  display: flex;
  align-items: center;

  right: 140px;
  border-style: none;
  color: azure;
}


.support-dev-button span {
  display: inline-flex;
  align-items: center;
  line-height: 20px; /* ปรับ line-height ให้ตรงกับความสูงของรูปภาพ */
}
.support-dev-button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}


.privacy-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #000000 0%, #11111a 100%);
  color: gray;
  z-index: 1000;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
}

.privacy-content {
  padding: 50px;
  margin: auto;
}

.switch {
  position: absolute;
  top: 10px;
  right: 25px;
  width: 70px;
  height: 35px;
  background-color: rgb(65, 66, 66);
  display: flex;
  align-items: center;
  border-radius: 30px;
  margin-top: 48px;
  padding: 5px;
  cursor: pointer;
  z-index: 1;
}

.switch[data-language="th"] {
  justify-content: flex-start;
}

.switch[data-language="en"] {
  justify-content: flex-end;
}

.handle {
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.87);
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.274);
  position: absolute;
  transition: transform 0.3s ease;
}

.language-label {
  width: 40px;
  height: auto;
  margin: 0 10px;
  color: rgba(0, 0, 0, 0.993);
}


/* Media Queries */
@media (max-width: 1024px) {
  /* สำหรับ iPad */

  .switch {
    margin-top: 100px;
  }

  .characters-content{
    margin-top: 20%;
  }
  .story-content{
    margin-top: 20%;
  }
  .story-image,
  .characters-image {
    width: 100%; /* ปรับขนาดของภาพ */
    margin-right: 0; /* ลบ margin ขวา */
  }

  .image-container {
    flex-direction: column; /* จัดภาพในแนวตั้ง */
  }

  .story-buttons,
  .characters-buttons button {
    font-size: 15px;
  }

  .menu {
    top: 3%; /* ลดระยะห่างด้านบน */
    gap: 10px; /* ลดระยะห่างระหว่างเมนู */
  }

  .menu img {
    width: 25px; /* ปรับขนาดรูปภาพ */
    height: 20px; /* ปรับขนาดรูปภาพ */
  }


  .logo img {
    width: 80%; /* ปรับขนาดของภาพโลโก้ */
  }

  .gallery-grid {
    grid-template-columns: repeat(2, 1fr); /* ลดจำนวนคอลัมน์ */
  }

  .gallery-item {
    height: 300px; /* ปรับขนาดของภาพ */
  }

  .toggle-music {
    bottom: 100px; /* ปรับตำแหน่งของปุ่มเพลง */
  }

  .black-bar {
    height: 8vh; /* ลดความสูงของแถบสีดำ */
  }
}

@media (max-width: 768px) {
  /* สำหรับ iPhone */
  .switch {
    margin-top: 15px;
  }

  .story-image,
  .characters-image {
    width: 100%; /* ปรับขนาดของภาพ */
  }

  .image-container {
    flex-direction: column; /* จัดภาพในแนวตั้ง */
  }

  .story-image,
  .characters-image {
    margin-right: 0; /* ลบ margin ขวา */
  }
  .menu {
    display: none; /* ซ่อนเมนูหลัก */
  }
  .support-dev-button{
    bottom: 20%;
  }


  .hamburger-menu {
    display: flex; /* แสดง hamburger menu */
  }
  .dropdown-menu{
    left: 50px;
  }

  .dropdown-menu img{
    height: 30px;
    object-fit:contain;
  }

  .logo img {
    width: 100%; /* ปรับขนาดของภาพโลโก้ */
  }
  .update-panel,
  .contact-form-panel {
    width: 100%; /* ครึ่งหนึ่งของหน้าเว็บ */
  }

  .gallery-item {
    height: 200px; /* ปรับขนาดของภาพ */
  }
  .gallery-content {
    margin-top: 15px;
  }

  .toggle-music {
    bottom: 80px; /* ปรับตำแหน่งของปุ่มเพลง */
  }

  .black-bar {
    height: 6vh; /* ลดความสูงของแถบสีดำ */
  }

}
